<template>
  <div class="home-container">
    <van-nav-bar class="nav-bar newnavbar" title="FUNLOVES">
      <template #right>
        <van-icon name="chat-o" size="24" color="#fff" @click="toService" />
        <span v-show="!token" style="font-size: 16px; color: #fff;" @click="toZC">{{ $t('login') }}</span>
      </template>
      <template #left>
        <span v-show="!token" style="font-size: 16px; color: #fff;" @click="toLogin">{{ $t('register') }}</span>
      </template>
      <template #title>
        <van-image class="menu-item-icon" style="margin: 10px 0 0 0;" height="40"
          :src="config.baseURL + $store.getters.getBaseInfo.ico"> </van-image>
      </template>
    </van-nav-bar>
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner">
        <!-- <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper> -->
        <van-swipe :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="(v, key) in banners" :key="key">
            <van-image :src="v.url" :height="160" width="100%" fit="cover">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
              <template v-slot:error><van-image src="/img/error.png" /></template>
            </van-image>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="notice-bar" style="padding: 8px; background: rgb(243, 243, 245);">
        <van-notice-bar class="notice-swipe" :left-icon="require('@/assets/laba.png')" background="#ffffff"
          color="#3e1e88" :text="this.notice" />
        <div class="linear-gradient"></div>
      </div>
      <div style="padding: 10px;">
        <div style="margin-bottom: 10px; font-size: 16px;">{{ $t('highEndZone') }}
          <i style="background: #3f3a5b; padding: 4px; color: #ebcaaf; font-size: 12px; border-radius: 8px 0 8px 0;">{{
          $t('EnjoyTheUltimateLuxuryExperience') }}</i>
        </div>
        <div style="display: flex; width: 100%; overflow-y: auto; flex-wrap: nowrap;">
          <div class="gdgride" style="margin-right: 5px;" v-for="(v, i) in datalist" :key="i">
            <div v-for="(v2, i2) in v" :key="i2" @click="profile(v2)">
              <van-image :src="v2.img_url" :height="100" :width="100" :radius="8" fit="cover">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error><van-image src="/img/error.png" /></template>
              </van-image>
              <span><i>{{ $t('airborne') }}</i></span>
              <div
                style="position: absolute; bottom: 10px; text-align: center; color: #fff; width: 100%; font-size: 12px;">
                {{ $i18n.locale == 'ja' ? (v2.xuanfei_name || '').split('|')[1] :
          (v2.xuanfei_name
            || '').split('|')[0] }}</div>
            </div>
            <!-- <div>
              <van-image :src="v.img_url" :height="100" :width="100" :radius="8" fit="cover">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error><van-image src="/img/error.png" /></template>
              </van-image>
              <span><i>飛行機</i></span>
              <div
                style="position: absolute; bottom: 10px; text-align: center; color: #fff; width: 100%; font-size: 12px;">
                {{ v.xuanfei_name }}</div>
            </div> -->
          </div>
          <div
            style="width: 23px; padding: 20px 5px 10px; height: 145px; margin-top: 30px; background: #f9f9f9; color: #999; font-size: 12px;"
            @click="toXF">{{ $t('viewMoreData') }}</div>
        </div>
      </div>
      <div style="padding: 10px;">
        <div style="font-size: 16px;">{{ $t('aboutCannonRecommend') }}</div>
        <div>
          <horizontalItem v-for="(v, i) in datalist2" :key="i" :rowData="v"></horizontalItem>
        </div>
      </div>
      <div
        style="background: linear-gradient(180deg,#fe5986,#ff848b); width: 96%; margin: 0 auto 30px; border-radius: 8px; overflow: hidden; padding-bottom: 10px;">
        <div style="color: #ffe869; text-align: center; padding: 10px 0;">{{ $t('current') }} 1590 {{
          $t('peopleNowMatch')
        }}</div>
        <vue-seamless-scroll :data="listData" :class-option="{
          singleHeight: 18
        }" class="OnlineMatching">
          <ul class="item">
            <li v-for="(item, index) in listData" :key="index">{{ item.title }}</li>
          </ul>
        </vue-seamless-scroll>
      </div>

      <!-- <div class="hot-game">
        <div class="hot-title-div">
          <div style="    align-items: end;">
            <img src="@/assets/sex.png" alt="" style="width: 25px; height: 25px; margin-right: 10px;">
            <span>hoạt động sex</span>
          </div>
          <div @click="gotoMenu('/Game')" style="color: #ed37ae;">
            <span style="color: #ed37ae;">nhiều hơn</span>
            <van-icon name="arrow" color="#ed37ae" />
          </div>
        </div>
        <div class="hot-items-div">
          <van-grid :border=false :column-num="4" :icon-size="20">
            <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <span>{{ v.name }}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div> -->
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" v-if="false">
        <div class="hot-recommend">
          <!-- <div class="hot-title-div">
            <div>
              <span>人气排行</span></div>
            <div>
              <span @click="gotoMenu('/Video')">nhiều hơn</span>
              <van-icon name="arrow" color="#979799" />
            </div>
          </div> -->
          <!-- <div class="movie_list_0">
            <swiper class="movie_swiper" :options="movielistSwiperOption">
              <swiper-slide v-for="(v,key) in movielist_0" :key="key" >
                <van-image class="movie_cover" @click="toPlayVideo(v.id)"  round :src="v.cover">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.title}}</span>
                    <div class="van-count-down">{{v.time}}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div> -->
          <div class="hot-title-div">
            <div style="    align-items: end;">
              <img src="@/assets/hua.png" alt="" style="width: 25px; height: 25px; margin-right: 10px;">
              <span>giới thiệu phim</span>
            </div>
            <div @click="gotoMenu('/Video')" style="color: #ed37ae;">
              <span style="color: #ed37ae;">nhiều hơn</span>
              <van-icon name="arrow" color="#ed37ae" />
            </div>
          </div>
          <div class="movie_list_1">
            <div class="movie-list-item" v-for="(v, key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
              <van-image class="cover_img" round :src="v.cover">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error><van-image src="/img/error.png" /></template>
              </van-image>
              <div class="movie-list-item-bottom">
                <div class="movie-time-div">
                  <span>{{ v.title }}</span>
                  <span style="font-size: 12px;">通る:{{ v.count }}</span>
                </div>
              </div>
            </div>
            <div class="hot-recommend-more" @click="gotoMenu('/Video')">nhiều hơn</div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import horizontalItem from '@/common/horizontalItem.vue'
import vueSeamlessScroll from 'vue-seamless-scroll'
import config from "../../http/config";
export default {
  components: {
    horizontalItem,
    vueSeamlessScroll
  },
  data() {
    return {
      config,
      token: "",
      datalist: [[]],
      datalist2: [],
      listData: [],
      notice: "",
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      }
    };
  },
  methods: {
    profile(v) {
      this.$router.push({
        path: '/profile?id=' + v.id
      });
    },
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    randomWord(randomFlag, min, max) {
      let pos = '';
      var str = "",
        range = min,
        arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      // 随机产生
      if (randomFlag) {
        range = Math.round(Math.random() * (max - min)) + min;
      }
      for (var i = 0; i < range; i++) {
        pos = Math.round(Math.random() * (arr.length - 1));
        str += arr[pos];
      }
      return str;
    },
    toService() {
      return window.location.href = this.$store.getters.getBaseInfo.kefu;
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
      }

    },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/PlayVideo?id=' + id })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t('refreshSuccessful'));
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        console.log("sys_config", res)
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
      })

    },
    getNotice(data) {
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1
    },
    getBanner(data) {
      data.banners.map(v => { v.url = this.$config.baseURL + v.url });
      this.banners = data.banners;
    },

    toXF() {
      this.$router.push({
        path: 'Choose'
      });
    },
    toZC() {
      localStorage.clear()
      this.$router.push({
        path: 'Register'
      });
    },
    toLogin() {
      localStorage.clear()
      this.$router.push({
        path: 'Login'
      });
    },
  },
  mounted() {
  },
  created() {
    this.token = localStorage.getItem('token');
    this.getBasicConfig();
    for (let i = 0; i < 10; i++) {
      this.listData.push({ title: this.randomWord(true, 8, 10) + `  ${this.$t('accountMembership')}` })
    }
    this.$http({
      method: 'get',
      url: 'xuanfeilist',
      data: {
        id: 1,
        page: 1,
      }
    }).then(res => {
      this.datalist = this.group(res.data, 2);
      this.datalist2 = res.data;
      console.log("xuanfeilist", this.datalist2);
    });
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.gdgride {
  padding-right: 20px;

  >div {
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    >span {
      position: absolute;
      z-index: 9;
      background-color: #ebcaaf;
      color: #8d684b;
      transform: rotate(45deg);
      width: 150px;
      height: 150px;
      font-size: 24px;
      top: -80px;
      right: -80px;

      >i {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bold;
      }
    }
  }
}

.linear-bg {
  height: 170px;
  // background: linear-gradient(270deg, #e6c3a1, #7e5678);
  // background: linear-gradient(30deg, #7d76ef, #d63f8c);
}

.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

::v-deep .van-notice-bar__left-icon {
  font-size: 32px;
}

.notice-swipe {
  // width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
  border-radius: 50px;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
  margin-top: -23%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

// .banner_img {
//   border-radius: 10px;
//   width: 100%;
//   height: 100%;
// }

.hot-game {
  width: 100%;
  height: 100%;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div>div:first-child {
  width: 50%;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 25px;
  color: #979799;
}

// .hot-title-div>div:first-child span:before {
//   content: "";
//   display: block;
//   width: 5px;
//   height: 30px;
//   background-color: #7e5678;
//   border-radius: 1px;
//   margin-right: 5px;
// }

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}

.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}

.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}

.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px
}

.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}

.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}

.movie_list_0 span {
  font-size: 30px;
}

.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 335px;
  height: 290px;
}

.home-scroll {
  padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 25px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}

.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}

.hot-items-div .game_item_img {
  width: 120px;
  height: 150px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.newnavbar {
  // background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
  background: linear-gradient(30deg, #7d76ef, #d63f8c);

}

.OnlineMatching {
  height: 210px;
  overflow: hidden;


  li {
    text-align: center;
    color: #fff;
    font-size: 28px;
    margin-top: 10px;
  }
}
</style>
