<template>
  <div class="container page">
    <van-nav-bar :title="$t('taskRecord')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-empty image-size="50px" v-if="list.length === 0" :description="$t('emptyData') + ''" />
        <div v-else class="item_list" v-for="(v, key) in list" :key="key">
          <div class="lottery_info">
            <van-image class="cover" :src="$config.baseURL + v.ico">
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <span class="period-number">{{ v.expect }}</span>
            <span class="period-number">
              {{ $i18n.locale == 'ja' ? (v.name || '').split(',')[1] : (v.name || '').split(',')[0] }}
              <!-- {{ v.name }} -->
            </span>
          </div>
          <div class="recent">
            <div class="kuaisan-ball left">
              <!-- <van-image class="res-img"
                :src="v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[0] + '.png'">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <van-image class="res-img"
                :src="v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png'">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <van-image class="res-img"
                :src="v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png'">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image> -->
              <span class="resgardenBox">{{ v.status === 0 ? 0 : v.opencode[0] }}</span>
              <span>+</span>
              <span class="resgardenBox">{{ v.status === 0 ? 0 : v.opencode[1] }}</span>
              <span>+</span>
              <span class="resgardenBox">{{ v.status === 0 ? 0 : v.opencode[2] }}</span>
              <span>=</span>
              <span class="resgardenBox">{{ v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] +
      v.opencode[2] }}</span>
              <!-- <span style="width: 20px;" class="res-des middle">{{ v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] +
                v.opencode[2] }}</span> -->
              <span class="ressquareBox">{{ v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] +
      v.opencode[2]) > 10 ?
      $t('big') : $t('small') }}</span>
              <span class="ressquareBox">{{ v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] +
      v.opencode[2]) % 2 ===
      0 ? $t('two') : $t('one') }}</span>
            </div>
          </div>
          <div class="topInfo">
            <span v-if="v.status === 1" style="color: #07c160">{{ v.status_text }}</span>
            <span v-else>{{ v.status_text }}</span>
            <!-- <span>{{ $t('betPoints') }}：{{ v.money }}</span> -->
          </div>
          <!--   <div class="topInfo">
            <span v-if="v.is_win === 1" style="color: #07c160">{{v.win_text}}</span>
            <span v-else >{{v.win_text}}</span>
            <span>积分：{{v.profit}}</span>
          </div> -->
          <!-- <div class="topInfo">
            <span>任务类型：</span>
            <span>{{v.type}}</span>
          </div> -->
          <div class="time">
            <span style="font-size: 16px; font-weight: bold;">{{ $t('betPoints') }}：{{ v.money }}</span>
          </div>
          <div class="time">
            <span>{{ $t('orderTime') }}：{{ v.create_time }}</span>
          </div>
          <div class="time">
            <span>{{ $t('settlementTime') }}：{{ v.update_time }}</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list: [],
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('refreshSuccessful'));
        this.isLoading = false;
      }, 500);
    },
    getUserGameList() {
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res => {
        if (res.code === 200) {
          console.log('user_get_game_list', res.data)
          this.list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    }

  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}

.container .main {
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list {
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span {
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}

.item_list .time span {
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child {
  float: right;
}

.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}

.item_list .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.item_list .period-number {
  margin-left: 50px;
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

.item_list .lottery_info {
  display: flex;
}

.recent {
  display: flex;
  align-items: center;
  height: 100px;
}

.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 15px;
}

.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 35px;
}

.resgardenBox {
  background: linear-gradient(90deg, #f560cd, #4f2b8a);
  color: #fff;
  width: 52px;
  height: 52px;
  line-height: 52px;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  // margin: 0 5px;
}

.ressquareBox {
  background: linear-gradient(90deg, #f560cd, #4f2b8a);
  color: #fff;
  height: 52px;
  line-height: 52px;
  border-radius: 8px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  // margin-left: 5px;
  padding: 0 20px;
  min-width: 100px;
}
</style>
