export default {
    tabbar1: "Beranda",
    tabbar2: "Video",
    tabbar3: "Pilih Putri",
    tabbar4: "Pemilihan",
    tabbar5: "Pribadi",
    login: "Masuk",
    userNamePlaceholder: "Masukkan nama pengguna",
    passwordPlaceholder: "Masukkan kata sandi",
    forgotPassword: "Lupa kata sandi?",
    noAccount: "Tidak punya akun?",
    registerNow: "Daftar sekarang",
    register: "Daftar",
    inviteCodeePlaceholder: "Masukkan kode undangan",
    consentAgreement: 'Saya sudah membaca dan menyetujui semua ketentuan "Perjanjian Pembukaan Akun"',
    highEndZone: "Zona Mewah",
    EnjoyTheUltimateLuxuryExperience: "Nikmati Pengalaman Kemewahan Terbaik",
    airborne: "Tiba-tiba",
    viewMoreData: "Lihat lebih banyak data",
    aboutCannonRecommend: "Rekomendasi Kencan",
    current: "Saat ini",
    peopleNowMatch: "orang online cocok",
    accountMembership: "Keanggotaan akun kencan berhasil",
    highEnd: "Mewah",
    authentication: "Otentikasi",
    videoAuthentication: "Otentikasi Video",
    tollRange: "Rentang Biaya",
    personHeight: "Tinggi Badan",
    bust: "Lingkar Dada",
    serviceCity: "Kota Layanan",
    nationalAirborne: "Turun Tiba-tiba Nasional",
    askHerOut: "Ingin Mengajak Dia",
    uptodate: "Terbaru",
    play: "Putar",
    reservation: "Saya Ingin Memesan",
    beautifulGirl: "Gadis Cantik",
    people: "orang",
    requiredPromotion: "Kenaikan yang Diperlukan",
    integral: "Poin",
    rechargePoints: "Isi Ulang Poin",
    redeemPoints: "Tukar Poin",
    myActivityPoints: "Poin Aktivitas Saya",
    currencySymbol: "Poin",
    basicInformation: "Profil",
    personalReports: "Laporan Pribadi",
    loginPassword: "Sandi Akun",
    paymentPassword: "Kata Sandi Pembayaran",
    taskLog: "Riwayat",
    noticeAnnouncement: "Pengumuman Sistem",
    onlineService: "Layanan Online",
    logOut: "Keluar",
    residence: "Tempat Tinggal",
    reservationNow: "Pesan Sekarang",
    photo: "Foto",
    video: "Video",
    personalProfile: "Profil Pribadi",
    popularRecommendations: "Rekomendasi Populer",
    switchWindows: "Ganti Jendela",
    designatedTime: "Waktu yang Ditentukan",
    votingResults: "Hasil Pemungutan Suara",
    ISSUE: "Nomor Isu",
    data: "Data",
    big: "Mawar",
    small: "Bunga lili",
    one: "Bintang",
    two: "Balon",
    currentSelectedNumber: "Nomor Terpilih Saat Ini",
    pointsPerOrder: "Poin per Pesanan",
    inTotal: "Total",
    amountMoney: "Jumlah Uang",
    availablePoints: "Poin yang Tersedia",
    submitTo: "Kirim ke",
    artilleryEngagement: "Kegiatan Kencan",
    recharge: "Isi Ulang",
    payeeName: "Nama Penerima",
    bankName: "Nama Bank",
    bankCardNumber: "Nomor Kartu Bank",
    bankCardNumberPlaceholder: "Masukkan nomor kartu bank",
    branchName: "Nama Cabang",
    branchNumber: "Nomor Cabang",
    selectBank: "Pilih Bank",
    nameOfPayer: "Nama Pembayar",
    uploadPaymentVoucher: "Unggah Bukti Pembayaran",
    rechargePrompt: "Jangan lakukan operasi berulang. Jika pembayaran tidak diterima dalam waktu lama, silakan hubungi pelanggan.",
    confirmRecharge: "Konfirmasi Isi Ulang",
    exchangeRecords: "Catatan Penukaran",
    whole: "Semua",
    limitDescription: "Deskripsi Batas",
    singleTransactionLimit: "Batas Transaksi Tunggal",
    minimum: "Minimal",
    highest: "Maksimal",
    withdrawalFrequency: "Frekuensi Penarikan",
    dailyHighest: "Harian Maksimal",
    order: "pesanan",
    freeOfHandlingFees: "(Bebas Biaya Penanganan 500 kali)",
    receiptTime: "Waktu Penerimaan: Waktu penerimaan biasanya sekitar 5 menit, paling cepat dalam 2 menit",
    residualIntegral: "Sisa Poin",
    individual: "unit",
    redeemPointsNow: "Tukar Poin Sekarang",
    headSculpture: "Foto Profil",
    fullName: "Nama Lengkap",
    gender: "Jenis Kelamin",
    male: "Pria",
    female: "Wanita",
    unknown: "Tidak Diketahui",
    bankCardInformation: "Informasi Kartu Bank",
    have: "Ada",
    notHave: "Tidak Ada",
    modifyRealName: "Ubah Nama Asli",
    save: "Simpan",
    fullNamePlaceholder: "Masukkan nama",
    modifyRealNameRemake: "Untuk memastikan keamanan akun, nama asli harus sesuai dengan nama pada kartu bank yang terdaftar.",
    modifyGender: "Ubah Jenis Kelamin",
    cardNumber: "Nama Bank",
    cardName: "Nama Pengguna",
    cardID: "Nomor Kartu Bank",
    addBankCard: "Tambahkan Kartu Bank",
    addBankCardRemake: "Catatan: Silakan konsultasikan dengan bank komersial besar. Jika perlu mengubah, silakan hubungi layanan pelanggan online.",
    payCardInfoPlaceholder: "Masukkan informasi kartu pembayaran Anda",
    addBankCardPrompt: "Pengguna yang terhormat, untuk memastikan keamanan dana Anda, silakan tautkan nama asli Anda dan atur kata sandi penarikan. Jika nama Anda tidak sesuai dengan nama akun, Anda tidak dapat menarik dana.",
    personalReport: "Laporan Pribadi",
    profitPoints: "Poin Keuntungan",
    profiCalculationFormula: "Rumus Perhitungan Keuntungan : Poin Menang - Poin Tugas",
    taskPoints: "Poin Tugas",
    withdrawalPoints: "Poin Penarikan",
    winningPoints: "Poin Menang",
    changeLoginPassword: "Ubah Kata Sandi Masuk",
    oldPwd: "Kata Sandi Lama",
    oldPwdPlaceholder: "Masukkan kata sandi lama Anda",
    newPwd: "Kata Sandi Baru",
    newPwdPlaceholder: "Masukkan kata sandi baru Anda",
    againOldPwdPlaceholder: "Masukkan kembali kata sandi baru Anda",
    taskRecord: "Catatan Tugas",
    emptyData: "Data Kosong",
    betPoints: "Poin Pesanan",
    orderTime: "Waktu Pesanan",
    settlementTime: "Waktu Penyelesaian",
    refreshSuccessful: "Penyegaran Berhasil",
    invalidFunction: "Fungsi ini tidak valid",
    appointmentSuccessful: "Silakan hubungi resepsionis",
    completeActivationTask: "Silakan selesaikan tugas aktivasi.",
    checkAgreement: "Silakan centang perjanjian pembukaan akun di bawah ini",
    integralError: "Kesalahan Poin",
    selectNumber: "Silakan pilih nomor",
    fillInPoints: "Silakan isi poin",
    insufficientPoints: "Poin tidak mencukupi, silakan hubungi layanan pelanggan untuk isi ulang",
    contactCollect: "Silakan hubungi administrator untuk menerima tugas ini",
    successfulLotteryDraw: "Undian Berhasil, Nomor Isu:",
    notSet: "Tidak diatur",
    contactService: "Jika perlu mengubah kata sandi, silakan hubungi layanan pelanggan.",
    completeTask: "Silakan selesaikan tugas sebelum masuk",
    setUpBankCard: "Silakan atur kartu bank",
    accountOffline: "Akun offline",
    fileTooLarge: "File terlalu besar",
    formatRule: "Silakan unggah gambar dalam format yang benar",
    uploadSuccessful: "Unggahan Berhasil",
    amountError: "Kesalahan jumlah",
    setNameToBindBankCard: "Silakan atur nama sebelum mengikat kartu bank",
    setWithdrawalPasswordToBindBankCard: "Silakan atur kata sandi penarikan sebelum mengikat kartu bank",
    passwordInconsistency: "Kata sandi tidak konsisten",
    complete: "Silakan isi dengan lengkap",
    changWithdrawalPasswordContactSustomerService: "Kata sandi penarikan telah diatur, untuk mengubah silakan hubungi layanan pelanggan",
    activateWatchVideo: "Silakan aktifkan sebelum menonton video",
    rechargeWatchVideo: "Silakan isi ulang sebelum menonton video",
    pour: "Catatan",
    taskList: "Daftar Tugas",
    clearOrder: "Hapus Pesanan",
    confirmSub: "Konfirmasi Kirim",
    switchLanguage: "Ganti Bahasa",
    Chinese: "简体中文",
    English: "English",
    Japanese: "日本語",
    illustrate: "Ilustrasi",
    submissionTime: "Waktu Pengiriman",
    auditTime: "Waktu Audit",
    payPwdPl: "Masukkan kata sandi pembayaran",
    enterPayPwdPl: "Masukkan kembali kata sandi pembayaran",
    setPayPwd: "Atur kata sandi pembayaran",
    yse: "Ya",
    yinni:"Indonesia",
};
