var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"movie-hall page"},[_c('van-nav-bar',{staticClass:"nav-bar newnavbar",attrs:{"title":_vm.$t('tabbar2')},on:{"click-right":_vm.toService},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('van-icon',{attrs:{"name":"chat-o","size":"24","color":"#fff"}})]},proxy:true}])}),_c('div',{staticStyle:{"background-color":"#fff","padding-bottom":"10px"}},[_c('van-tabs',{attrs:{"title-inactive-color":"#442889","title-active-color":"#c571cf","animated":"","swipeable":""},on:{"change":_vm.OnChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":_vm.$t('uptodate'),"name":888,"title-style":{
    fontWeight: 'bold',
    background: 'url(/img/new.png) no-repeat top right',
    backgroundSize: '30%',
  }}}),_vm._l((_vm.videolitem),function(v,key){return _c('van-tab',{key:key,attrs:{"title":_vm.$i18n.locale == 'ja' ? (v.name || '').split(',')[1] : (v.name || '').split(',')[0],"name":v.id,"title-style":{
    fontWeight: 'bold',
  }}})})],2)],1),_c('swiper',{ref:"swiper",staticClass:"video_swiper",staticStyle:{"background-color":"#fff"},attrs:{"options":_vm.videoSwiperOption},on:{"slideChange":_vm.itemChange}},_vm._l((_vm.videolitem),function(v,key){return _c('swiper-slide',{key:key},[_c('div',{staticClass:"movie-list-tab"},[_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('div',{staticClass:"hot-recommend-div"},[_c('van-list',{attrs:{"finished":_vm.finished,"immediate-check":false,"finished-text":"no more"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"list-item"},_vm._l((_vm.videolist),function(v,key){return _c('div',{key:key,staticClass:"movie-list-item",on:{"click":function($event){return _vm.toPlayVideo(v.id)}}},[_c('van-image',{staticClass:"cover_img",attrs:{"round":"","src":v.vod_pic,"width":"100%","height":"100px"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner","size":"20"}})]},proxy:true},{key:"error",fn:function(){return [_c('van-image',{attrs:{"src":"/img/error.png"}})]},proxy:true}],null,true)}),_c('div',{staticClass:"movie-list-item-bottom",staticStyle:{"bottom":"33px"}},[_c('div',{staticClass:"movie-time-div"},[_c('span',[_vm._v(_vm._s(v.vod_name))]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.$t('play'))+":"+_vm._s(v.count))])])])],1)}),0)])],1)])],1)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }