<template>
    <div class="container page">
        <van-nav-bar :title="$t('switchLanguage')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <!-- <van-cell @click="tabLan('zh')" :title="$t('Chinese')" is-link></van-cell> -->
        <!-- <van-cell @click="tabLan('en')" :title="$t('English')" is-link></van-cell> -->
        <van-cell @click="tabLan('yn')" :title="$t('Indonesia')" is-link></van-cell>
        <van-cell @click="tabLan('en')" title="English" is-link></van-cell>
    </div>
</template>

<script>
export default {
    name: "switchLanguage",
    inject: ['reload'],
    data() {
        return {
            fromPage: '',
        }
    },
    created() {
        this.fromPage = this.$route.query.fromPage;
    },
    methods: {
        back() {
            return window.history.back();
        },
        tabLan(lang) {
            this.$http({
                method: 'get',
                data: {
                    lang: lang,
                },
                url: 'replaceLang'
            }).then(res => {
                if (res.code === 200) {
                    this.$i18n.locale = lang;
                    localStorage.setItem("language", lang);
                    this.reload();
                    this.fromPage && this.fromPage == 'login' ? this.$router.go(-1) : this.$router.push({ path: '/Home' });
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
::v-deep .van-cell {
    padding: 40px 60px;
    margin-top: 30px;
    font-size: 32px;
}
</style>